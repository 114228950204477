<template>
	<div
		v-if="course"
	>
		<section class="section section-banner">
			<div class="content">
				<Breadcrumb :page-name="course.name" />
				<div
					class="banner"
					:style="imageCourse"
				>
					<div class="banner__content">
						<h2 class="title-mono">
							курс
						</h2>
						<h1 class="title-section">
							{{ course.name }}
						</h1>
						<div class="banner__text">
							{{ course.subtitle }}
						</div>
						<div class="banner__stats">
							<div class="banner__members">
								{{ course.students_enrolled }}
							</div>
							<div class="banner__duration">
								{{ course.duration | durationFilter }}
							</div>
						</div>
						<div
							v-if="
								buttonSendText === 'Продолжить изучение'
							"
							class="progress-banner"
						>
							<div
								class="progress-banner__line"
								:style="
									`width: ${course.progress_percent}%;`
								"
							/>
						</div>
						<div class="banner__button">
							<BaseButton
								class="button md"
								:shadow-border="
									buttonSendText === 'Записаться на курс'
								"
								:class="{
									'blue base-button--blue--shadow':
										buttonSendText ===
										'Записаться на курс',
									orange:
										buttonSendText ===
										'Зарегистрироваться' ||
										buttonSendText ===
										'Продолжить изучение'
								}"
								:is-preloader="isLoading"
								:blue="isLoading"
								@clickButton="sendProposal"
							>
								{{ buttonSendText }}
							</BaseButton>
						</div>
					</div>
				</div>
			</div>
		</section>

		<SectionTags
			v-if="course.course_page_tags && course.course_page_tags.length"
			:tags="course.course_page_tags"
		/>

		<section
			v-if="course.about_course"
			class="section section-about"
		>
			<div class="content">
				<div class="about">
					<div class="about__content">
						<h2 class="title-section">
							О курсе
						</h2>
						<div
							class="about__text"
							v-html="course.about_course"
						/>
					</div>
					<div class="about__pic-wrap">
						<img
							src="https://ea5ec095-4ab2-4f77-811e-49e733f2acc8.selcdn.net/static_video/pic__racoon-4--animated--v2.gif"
							alt="Енот"
							class="about__pic"
						>
					</div>
				</div>
			</div>
		</section>

		<SectionAudience
			v-if="course.for_whom_blocks && course.for_whom_blocks.length"
			:blocks="course.for_whom_blocks"
		/>

		<SectionSkills
			v-if="course.what_you_will_learn_blocks && course.what_you_will_learn_blocks.length"
			:blocks="course.what_you_will_learn_blocks"
		/>

		<SectionVideo
			v-if="course.course_page_video"
			:url="course.course_page_video"
		/>

		<SectionImages
			v-if="courseSliderImages"
			:images="courseSliderImages"
		/>

		<section
			v-if="course.partners && course.partners.length"
			class="section section-brands"
		>
			<div class="content">
				<div class="brands">
					<VueSlickCarousel
						v-bind="brandsCarouselSettings"
						class="brands__slider"
					>
						<div
							v-for="(partner, index) in course.partners"
							:key="index"
						>
							<div class="brand">
								<!--								<div class="brand__title">-->
								<!--									Главный партнер-->
								<!--								</div>-->
								<img
									:src="partner.image_partner_course[0].image"
									alt="LogoPartner"
									class="brand__logo"
								>
							</div>
						</div>
					</VueSlickCarousel>
				</div>
			</div>
		</section>

		<section
			v-if="course.sections_course && course.sections_course.length"
			class="section section-program"
		>
			<div class="content">
				<div class="program">
					<h2 class="title-section">
						Программа курса
					</h2>
					<div class="program__list">
						<CourseProgram
							v-for="(item, index) in course.sections_course"
							:key="item.id"
							:course-program="item"
							:count="index + 1"
							:course-session="course.course_sessions[0]"
							:open-all-program="isOpenAllProgram"
							:complete-course="course.complete"
							:is-started-course="isStarted"
						/>
					</div>
					<div class="button-wrap">
						<BaseButton
							class="button"
							:shadow-border="
								buttonSendText === 'Записаться на курс'
							"
							:class="{
								'orange base-button--orange--shadow':
									buttonSendText ===
									'Записаться на курс',
								orange:
									buttonSendText ===
									'Зарегистрироваться' ||
									buttonSendText ===
									'Продолжить изучение'
							}"
							:is-preloader="isLoading"
							:blue="isLoading"
							@clickButton="sendProposal"
						>
							{{ buttonSendText }}
						</BaseButton>
					</div>
				</div>
			</div>
		</section>

		<SectionCertificate
			v-if="course.show_certificate_in_the_course_page"
		/>

		<section
			v-if="course.experts.length"
			class="section section-experts"
		>
			<div class="content">
				<div class="slider-wrap">
					<VueSlickCarousel
						class="experts-slider"
						v-bind="expertsCarouselSettings"
					>
						<div
							v-for="(expert, index) in course.experts"
							:key="index"
						>
							<div class="expert">
								<div
									v-if="expert.image_expert[0]"
									class="expert__avatar"
									:style="expert.image_expert[0].image | bgrDynamicImageCover"
								/>
								<div class="expert__content">
									<div class="title-mono">
										ЭКСПЕРТ КУРСА
									</div>
									<h3 class="title-block">
										{{ expert.name }}
									</h3>
									<div class="expert__position">
										{{ expert.position }}
									</div>
								</div>
							</div>
						</div>
					</VueSlickCarousel>
				</div>
			</div>
		</section>

		<section
			v-if="course.recommended_books.length"
			class="section section-books"
		>
			<div class="content">
				<h2 class="title-section">
					Рекомендованные книги
				</h2>
				<div class="books">
					<div
						v-for="(book, index) in course.recommended_books"
						:key="index"
						class="book"
					>
						{{ book.name }}
					</div>
				</div>
			</div>
		</section>

		<section
			v-if="recommendedCourses && recommendedCourses.length"
			class="section section-courses"
		>
			<div class="content">
				<h2 class="title-section">
					Еще курсы по теме
				</h2>
				<div class="slider-wrap">
					<VueSlickCarousel
						v-bind="carouselSettings"
						:dots="false"
						class="courses-slider"
					>
						<div
							v-for="course in recommendedCourses"
							:key="course.id"
						>
							<BaseCardCourse :course="course" />
						</div>
					</VueSlickCarousel>
				</div>
			</div>
		</section>

		<section
			v-if="!isLogged"
			class="section section-action"
		>
			<div class="content">
				<div class="action">
					<div class="action__text">
						Регистрируйся на нашей платформе и получи доступ к этим и многим другим бесплатным курсам, конкурсам и проектам по профориентации.
					</div>
					<button
						class="button"
						@click="modalOpen('regIn')"
					>
						Зарегистрироваться
					</button>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import BaseButton from '@/components/BaseComponents/BaseButton';
import CourseProgram from './CourseProgram.vue';
import BaseCardCourse from '@/components/BaseComponents/BaseCardCourse';
import SectionTags from '@/components/learning/SectionTags';
import SectionSkills from '@/components/learning/SectionSkills';
import SectionCertificate from '@/components/learning/SectionCertificate';
import SectionAudience from '@/components/learning/SectionAudience';
import SectionVideo from '@/components/learning/SectionVideo';
import SectionImages from '@/components/learning/SectionImages';

export default {
    name: 'Course',
	components: {
		Breadcrumb,
		CourseProgram,
		BaseButton,
        BaseCardCourse,
		SectionTags,
		SectionSkills,
		SectionCertificate,
		SectionAudience,
		SectionVideo,
		SectionImages,
	},
	data: () => ({
		course: null,
		courseCurrentLink: '#',
		isShowAllTopics: false,
		isSended: false,
		isLoading: false,
		recommendedCourses: [],
		isOpenAllProgram: false,
		urlCourseID: null,
        brandsCarouselSettings: {
            infinite: false,
            easing: 'swing',
            arrows: true,
            dots: false,
            autoplay: false,
            autoplaySpeed: 3000,
            pauseOnFocus: false,
            pauseOnHover: false,
            speed: 600,
            draggable: false,
            slidesToShow: 7,
            slidesToScroll: 1,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1000,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        },
        expertsCarouselSettings: {
            infinite: false,
            easing: 'swing',
            arrows: true,
            dots: false,
            autoplay: false,
            autoplaySpeed: 3000,
            pauseOnFocus: true,
            pauseOnHover: false,
            speed: 600,
            draggable: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false
        },
	}),
	asyncComputed: {
		processLink() {
			if (this.isStarted) {
				if (this.courseLink) return this.courseLink;
				else
					return (
						this.course &&
						`https://lk.lift-bf.ru/course_sessions/${this.course.course_sessions[0].out_id}/`
					);
			}

			if (this.isSended) return this.courseCurrentLink;
			else return '#';
		}
	},
	computed: {
		...mapState('user', ['id', 'token']),
		...mapGetters('tb', {
			courses: 'getAllCourses'
		}),
		...mapGetters('user', {
			isLogged: 'isLoggedIn',
			user: 'userInfo'
		}),
		idRegisterButton() {
		return this.buttonSendText === 'Зарегистрироваться'
				? `${this.course.id}-${this.course.slug}`
				: '';
		},
		allTimeProgram() {
			let result = this.course.sections_course.map(el => {
				return el.materials.reduce(function(prev, curr) {
					return prev + curr.duration;
				}, 0);
			});
			let resultTime = result.reduce(function(prev, curr) {
				return prev + curr;
			}, 0);
			return resultTime;
		},
		buttonSendText() {
			if (this.isStarted) {
				return 'Продолжить изучение';
			} else if (this.isLogged) {
				return 'Записаться на курс';
			} else {
				return 'Зарегистрироваться';
			}
		},
		viewedTopics() {
			if (this.isShowAllTopics) return this.course.topic;
			else return this.course.topic.slice(0, 6);
		},
		residueTopics() {
			let residue = this.course.topic.length - this.viewedTopics.length;

			if (residue < 0) return false;
			else if (residue === 0 && this.isShowAllTopics) return 'Свернуть';
			else if (residue === 1) return 'Ещё 1 категория';
			else if (residue && !this.isShowAllTopics)
				return `Ещё ${residue} категории`;
			else return false;
		},
		courseInf() {
			let course = this.course;
			return [
				{
					title: 'Зачем',
					description: course.titles ? course.titles : '',
					iconClass: 'gray',
					iconName: '1'
				},
				{
					title: 'Для кого',
					description: course.for_whom ? course.for_whom : '',
					iconClass: 'blue',
					iconName: '2'
				},
				{
					title: 'Что узнаете',
					description: course.description ? course.description : '',
					iconClass: 'orange',
					iconName: '3'
				}
			];
		},
		isStarted() {
			if (this.course) {
				return !!(
					this.user &&
					this.user.req_course_user &&
					this.user.req_course_user.some(
						req_course => req_course.course === this.course.id
					)
				);
			}
			return false;
		},
		courseLink() {
			let course =
				this.user &&
				this.user.req_course_user &&
				this.user.req_course_user.find(
					req_course => req_course.course === this.course.id
				);
			if (course) return course.course_link;
			return undefined;
		},
        imageCourse() {
            if (this.course && this.course.image_course.find(img => img.type === 'image_course')) return `background-image: url(${this.course.image_course.find(img => img.type === 'image_course').image});`
            else return 'background-image: url(https://lift-bf.ru/staticfiles/img/landscape-image.png);'
        },
		courseSliderImages() {
			return this.course?.image_course
				?.filter(image => image.type === 'course_slider')
				.reduce((a, b) => [...a, {url: b.image}], []);
		}
	},
	watch: {
		$route: {
			handler(route) {
				if (route.name === 'Course') {
					this.courses && this.getLoadedCourse();
					!this.courses && this.getUnloadedCourse();
					setTimeout(
						() =>
							this.$scrollTo(
								document.querySelector('header.header-role')
							),
						0
					);
				}
			}
		}
	},
	created() {
		let slug = this.$route.params.slug;
		this.urlCourseID = +slug.split('-')[slug.split('-').length - 1];
		this.getUnloadedCourse();
		document.title =
			'Бесплатный онлайн видео-курс - Лифт в будущее – образовательная онлайн-платформа';
	},
	mounted() {
		this.$scrollTo(document.querySelector('header.header-role'))
		if (this.$route.query.modal === 'reg-form-drugoye-delo' && !this.isLogged) this.modalOpen('regIn')
	},
	methods: {
		...mapActions('tb', ['getCourse', 'getAllCourses', 'startCourse']),
		openAllProgram() {
			this.isOpenAllProgram = !this.isOpenAllProgram;
		},
		goToCourse(course) {
			window.location.href = `/courses/${course.slug}-${course.id}`;
		},

		courseBgrImage(image) {
			if (image) return `background-image: url(${image});`;
			else
				return 'background-image: url(/staticfiles/img/landscape-image.png);';
		},
		sendProposal() {
			if (!this.isLogged) {
				this.modalOpen('regIn', 'course');
				return;
			}

			if (!this.course.course_sessions.length) {
				this.modalOpen('courseFail', 'Курс временно не доступен');
				return;
			}

			if (this.isStarted) {
				this.openInNewWindow(this.processLink);
                if (this.user.is_onbording === false) {
                    localStorage.removeItem('nameComponentOnboarding')
                    localStorage.removeItem('currentAim')
                    localStorage.removeItem('onboardingAnswers')
                    this.$router.push('/onboarding')
                }
				return;
			}

			if (!this.isStarted && this.isLogged) {
				this.isLoading = true;
                if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru')) {
                    this.$metrika.reachGoal('open_course')
                }
				this.startCourse({
					user: +this.id,
					course: this.course.id,
					course_session: this.course.course_sessions[0].id
				}).then(result => {
					this.courseCurrentLink = result.course_link;
					this.isLoading = false;
					this.isSended = true;
					this.processLink = this.courseCurrentLink;
					this.openInNewWindow(this.processLink);
                    if (this.user.is_onbording === false) {
                        localStorage.removeItem('nameComponentOnboarding')
                        localStorage.removeItem('currentAim')
                        localStorage.removeItem('onboardingAnswers')
                        this.$router.push('/onboarding')
                    }
				});
			}
		},
		openInNewWindow(url) {
			window.open(url, 'self');
		},
		getLoadedCourse() {
			this.courses.forEach(course => {
				if (course.id === this.urlCourseID) {
					this.course = course;
					let params = {
						token: this.token,
						type:'course'
					};
					if (course.section.length)
						params.section = course.section[0].id;
					this.getAllCourses(params).then(response => {
						this.recommendedCourses = response
							.filter(course => this.course.id !== course.id)
							.slice(0, 9);
					});
					this.initDescription();
				}
			});
		},
		getUnloadedCourse() {
			if (this.urlCourseID) {
				this.getCourse(this.urlCourseID).then(result => {
					let needCourse = result[0];
					this.course = {
						...needCourse
					};
					let params = {
						token: this.token,
						type:'course'
					};
					if (result[0].section.length)
						params.section = result[0].section[0].id;
					this.getAllCourses(params).then(response => {
						this.recommendedCourses = response.results
							.filter(course => this.course.id !== course.id)
							.slice(0, 9);
					});
					this.initDescription();
				});
			}
		},
		initDescription() {
			document.getElementById(
				'seo-title'
			).textContent = `Бесплатный онлайн видео-курс "${this.course.name}"`;
			document.title = `Бесплатный онлайн видео-курс "${this.course.name}" - Лифт в будущее – образовательная онлайн-платформа`;
			document.head
				.querySelector('meta[property="og:title"]')
				.setAttribute('content', document.title);
			document.head
				.querySelector('meta[name="description"]')
				.setAttribute(
					'content',
					`Пройди бесплатный онлайн видео-курс ${this.course.name}. ${this.course.description}`
				);
			document.head
				.querySelector('meta[property="og:description"]')
				.setAttribute(
					'content',
					`Пройди бесплатный онлайн видео-курс ${this.course.name}. ${this.course.description}`
				);
		}
	}
};
</script>

<style lang="less">
@import "../../assets/styles/pages/course";
</style>
